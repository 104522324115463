<template>
    <v-dialog v-model="dialog" max-width="450">
        <v-card>
            <v-card-title>
                Cuil usuario
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="body-1 pt-4">
                <v-col cols="12" sm="12" class="py-0">
                    Cuil del usuario
                    <v-text-field v-model.trim="cuil" outlined :rules="[rules.required, rules.cuil]" validate-on-blur
                        dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="py-0 d-flex justify-end">
                    <BtnConfirmar @action="validarAlta" nombre="confirmar" :sin_icono="true" />
                </v-col>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
export default {
    data() {
        return {
            cuil: '',
            rules: {
                required: value => !!value || 'Campo requerido',
                cuil: value => /^\d{11}$/.test(value) || 'El CUIL debe tener 11 dígitos y debe ser válido.'
            },
        };
    },
    props: {
        usuario: {
            type: Object,
            default: () => ({
                id: null,
                nombre: '',
                apellido: ''
            })
        },
        value: Boolean
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        async validarAlta() {
            this.$store.state.loading = true
            if (this.cuil && this.cuil.length === 11) {
                await this.$store.dispatch('usuarios/altaJarvis', {
                    usuario_id: this.usuario.id,
                    cuil: this.cuil,
                    nombre: this.usuario.nombre,
                    apellido: this.usuario.apellido
                }).then(res => {
                    if (res.exito) {
                        this.$store.dispatch('show_snackbar', {
                            text: res.message,
                            color: 'success'
                        })
                        this.dialog = false
                    } else {
                        this.$store.dispatch('show_snackbar', {
                            text: res.message,
                            color: 'error'
                        })
                    }
                }).catch(err => {
                    this.$store.dispatch('show_snackbar', {
                        text: err.message,
                        color: 'error'
                    })
                })
            } else {
                this.$store.dispatch('show_snackbar', {
                    text: 'El cuil ingresado no es correcto.',
                    color: 'warning'
                })
            }
            this.$store.state.loading = false
        }
    },
    components: {
        BtnConfirmar
    },

    watch: {
        dialog(val) {
            if (val) {
                this.cuil = ''
            }
        }
    }
};
</script>
